<template>
    <div class="gridMainLayout">
      <ul class="gridContainer">
        <li
          v-for="(item, ketSet) in contents"
          class="gridCardWrapper"
          :key="ketSet"
          @click.stop="handleCardClick(item)"
        >
          <div class="continueWatchCard gridCardLayout">
            <div class="gridPosterLayout">
              <!-- <img :src="getPoster(item)" class="continueWatchPoster" :alt="item.objectid" /> -->
              <ImageTemplate :content="item"   :key="ketSet + '?t=' + new Date().getTime()"></ImageTemplate>
              <img
                src="@/assets/icons/remove-watchlist.svg"
                class="removeFromWatch"
                @click.stop="removeFromWatchlist(item)"
                :alt="item.title"
              />
              <div class="play-img" style="display: block;" v-if="item && item.category == 'TVSHOW' && item.objecttype == 'CONTENT'">
                <PlayButton :whereIamFrom="'episode'" :content="item || null" :playerInstance="playerInstance"></PlayButton>
              </div>
              <!-- <div
                class="progressPercent"
                :style="{ width: calculatePercentage(item) + '%' }"
                :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
              ></div> -->
            </div>
            <div class="gridContentLayout">
              <div class="gridContentTitle">
                <p class="gridTitle" :title="item.title">{{ item.title }}</p>
              </div>
              <div class="gridContentDetails">
                <div class="main-info">
                  <div class="ua-rating" v-if="item.advisory">
                    <p>{{ item.advisory }}</p>
                  </div>
                  <div class="publish-year" v-if="item.productionyear">
                    <p>{{ item.productionyear }}</p>
                  </div>
                  <div class="duration" v-if="item.duration">
                    <p>{{ calcTimeFormat(item.duration) }}</p>
                  </div>
                  <div class="pgrating" v-if="item.channelno">
                    <p>CH NO : {{ item.channelno }}</p>
                  </div>
                  <div class="pgrating" v-if="item.catchupperiod">
                    <p>CU TV</p>
                  </div>
                  <div class="pgrating" v-if="item.pgrating">
                    <p>{{ item.pgrating }}</p>
                  </div>
                </div>
                <div class="main-genre">
                  <div class="sub-genre1" v-if="item.category">
                    <p>{{ $t(item.category) }}</p>
                  </div>
                  <div
                    class="sub-genre2"
                    v-if="item.genre"
                    :class="{ 'with-bullet': item.category }"
                  >
                    <p>{{ $t(item.genre) }}</p>
                  </div>
                  <div
                    class="sub-genre3"
                    v-if="item.contentlanguage && item.contentlanguage.length > 0"
                    :class="{ 'with-bullet': item.category || item.genre }"
                  >
                    <p>{{ $t(item.contentlanguage[0]) }}</p>
                  </div>
                </div>
              
              </div>
              <p class="gridDescription" v-if="item.shortdescription || item.longdescription">
                {{ getDescription(item, 150) }}
              </p>
            </div>
          </div>
        </li>
        <li class="gridCardWrapper" v-for="index in [1,2,3,4,5,6]" :key="index*2000" v-if="loader">
          <div class="continueWatchCard gridCardLayout">
            <div class="gridPosterLayout loading"></div>
          </div>
        </li>
      </ul>      
      <div ref="scrollObserver" style="opacity: 0; height: 20px; width:100%;" id="scrollObserver">
      </div>
    </div>
</template>
  
<script>
  import { eventBus } from "@/eventBus";
  import Utility from "@/mixins/Utility.js";  
  import Redirection from "@/mixins/redirection.js";
  import PlaybackUtilities from "@/mixins/playbackUtilities.js";
  import { mapGetters } from "vuex";
  
  export default {
    name: "Grid",
    props: {
        contents: {
            type: Array,
        },
        playerInstance: {
            type: Object,
        },
        isScrollable: {
            type: Boolean,
        },
        pageChangeKey: {
          type: Number
        }
    },
    data() {
      return {
        localDisplayLang: null,
        gotPosterItem: null,
        placeholder_port: require('@/assets/placeholder-port.svg'),
        placeholder_lands: require('@/assets/placeholder-lands.svg'),
        loader: false,
        scroll: true,
        count: 1
      }
    },
    watch: {
      pageChangeKey() {
        this.scroll = true;
      }
    },
    created() {
      
      this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
      this.localDisplayLang = this.localDisplayLang == 'ml' ? 'mal' : this.localDisplayLang;
    },
    mounted() {
      this.setupIntersectionObserver();
    },
    beforeDestroy() {
      if (this.observer) {
        // this.observer.disconnect();
        // this.contents = [];
      }
    },
    methods: {
      setupIntersectionObserver() {
        const options = {
          root: null,
          rootMargin: "0px",
          threshold: 0.55
        };

        this.observer = new IntersectionObserver(this.handleIntersect, options);
        let idScroll = document.getElementById('scrollObserver')
        this.observer.observe(idScroll);
      },
      handleIntersect(entries, observer) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // alert('out')
            if (this.isScrollable && this.scroll) {
              // alert('in')
              
              this.loader = true;
              this.$emit("loadMore");
              // this.scroll = false;
            } else {
              this.loader = false;
              this.observer.disconnect();
            }
            
          }
        });
      },
      removeFromWatchlist(item) {
        
        eventBus.$emit("removeFromContinueWatch", item);
      },
      calculatePercentage(item) {
        return (item.watchedduration / item.duration) * 100;
      },
      handleCardClick(content) {
        sessionStorage.setItem("isActiveDesktopView", true);
        let currentLanguage = this.getCurrentLanguageUrlBase();
        if(content && content.category == 'TVSHOW' && content.objecttype == 'CONTENT') {
          if(!this.subscriberid) {
            this.redirectToLogin();
          } else {
            this.getContentProgress(content).then(response => {
                  if (response) {
                    if (response.status === "INPROGRESS" && response.objecttype === "CONTENT") {
                      content.watchedTime = response.watchedduration;
                    }
                  }
            });   
            
            let episodesList = [content];
            let episodeIndex = 0;
            if(content.playbacktype == "EXTERNALURLBASEDPLAYBACK") {
              let isPlaybackAllowed = this.checkAvailabilityAllowsPlayback(episodeList[episodeIndex]);
              if (!isPlaybackAllowed) throw isPlaybackAllowed;
              this.actBuyDRMToPlayer(episodeIndex, episodesList, episodesList.length, true);
              this.showPlayer();
            } else {
              this.loadChosenIndexContent(episodeIndex, episodesList.length, false);
            }
          }
        return
        }
        this.redirectToDetailPage(content);
      }
    },
    computed: {
      ...mapGetters([
        "subscriberid",
        "availabilityList",
        "subscriptionList",
        "purchaseList",
        "profileid",
        "getRtl",
        "getContinueWatchData",
      ]),
    },
    components: {     
      PlayButton: () => import(/* webpackChunkName: "playButton" */ "@/components/buttons/play.vue"),
      ImageTemplate: () => import(/* webpackChunkName: "imageTemplate" */ "./Image.vue"),
    },
    mixins: [Utility, PlaybackUtilities, Redirection],
  }
</script>
  
<style lang='scss' scoped>
  @import "@/sass/_variables.scss";
  @import "@/sass/_components.scss";
  // @import "@/sass/_grid.scss";
  @import "./Grid.scss";
  
  .gridPosterLayout {
    background: linear-gradient(to bottom left, #303030, #242424, #1d1d1d);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
  }
</style>
  